import React from "react";
import {Button, Result} from 'antd';
import {NavLink} from "react-router-dom";


// TODO - if I have a Order Data in LS - I need to remove it.

const PaymentSuccessful = () =>
    <Result
        style={{paddingTop: 160}}
        status="success"
        title="The payment was successful!"
        subTitle="Thank you for your choice and cooperation!"
        extra={[
            <NavLink to={'/'}>
                <Button type="primary" key="console">
                    Go to main page
                </Button>
            </NavLink>,
        ]}
    />

export default PaymentSuccessful;