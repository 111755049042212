import restAPI from "../../../features/restAPI";
import {authPages} from "../../../routing/config";
import styled from "styled-components";


import React, {useContext, useState, useEffect} from "react";
import {AuthContext} from "../../auth/AuthContext";

import {LoadingOutlined} from "@ant-design/icons";
import {Col, List, Row, Spin, AutoComplete, Input, Button} from "antd";

import MainPageTitle from "../../../components/MainPageTitle";
import PatientDetails from "./PatientDetails";


const CustomAutoComplete = styled(AutoComplete)`

    & button {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
    }

    & input, & input:focus {
        border-color: #01a9ac;
    }
`


const MyPatients = () => {
    const {myPatients} = authPages;

    const [isLoading, setIsLoading] = useState(true);
    const [searchSTR, setSearchSTR] = useState("");
    const {authContext} = useContext(AuthContext); // auth data from current session
    const [patientList, setPatientList] = useState([]);  // list with patients
    const [open, setOpen] = useState(false);  // drawer state
    const [selectedPatient, setSelectedPatient] = useState(null);  // save a selected user in drawer

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const loadAllProviderPatients = async () => {
        try {
            setIsLoading(true);
            const response = await restAPI.get(`/patients/all/?web_provider__id=${authContext?.webProviderData?.id}`, authConfig);
            setPatientList(response?.data?.results);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const searchProviderPatients = async () => {
        try {
            setIsLoading(true);
            const response = await restAPI.get(`/patients/all/?search=${searchSTR}&web_provider__id=${authContext?.webProviderData?.id}`, authConfig);
            setPatientList(response?.data?.results);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = value => {
        setSearchSTR(value);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setSelectedPatient(null);
    };

    const onClickHandler = (patient) => {
        setSelectedPatient({...patient});
        showDrawer();
    };

    const DetailsButton = patient => <Button
        onClick={() => onClickHandler({...patient})}
        size={'small'}>
        Details
    </Button>


    useEffect(() => {
        (async () => {
            await loadAllProviderPatients();
        })();
        // eslint-disable-next-line
    }, []);

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{myPatients.title}</MainPageTitle>
            </Col>


            <Col xs={24} lg={24} xl={8}>
                <CustomAutoComplete
                    dropdownMatchSelectWidth={"100%"}
                    style={{width: "100%"}}
                    onChange={handleSearch}
                >
                    <Input.Search
                        size="middle"
                        placeholder="Search ..."
                        enterButton
                        onSearch={searchProviderPatients}
                        style={{margin: "0 0 10px 0"}}
                    />
                </CustomAutoComplete>
            </Col>


            {/*<Col xs={24} lg={24} xl={24}>*/}
            {/*    <Alert message={`At this moment we showing all our patients, because it's all test persons.`}/>*/}
            {/*</Col>*/}


            <Col xs={24} lg={24}>

                <List
                    size={'small'}
                    loading={isLoading}
                    itemLayout="horizontal"
                    // loadMore={loadMore}
                    // rowKey={(patient) => patient.id}
                    dataSource={patientList}
                    renderItem={(patient, index) =>

                        <List.Item key={index} actions={[DetailsButton(patient),]}>

                            <Button
                                color={'primary'} type={'text'} size={'small'}
                                onClick={() => onClickHandler({...patient})}
                            >
                                {patient.last_name} {patient.first_name}
                            </Button>

                            <PatientDetails
                                authContext={authContext}
                                selectedPatient={selectedPatient}
                                open={open}
                                onClose={onClose}
                                // productsList={productsList}
                                authConfig={authConfig}
                            />

                        </List.Item>}
                />

            </Col>
        </Row>
    </Spin>
}

export default MyPatients;