import dayjs from "dayjs";
import {loadStripe} from '@stripe/stripe-js';
import restAPI from "../../../features/restAPI";

import React, {useContext, useEffect, useState, useRef} from "react";
import {AuthContext} from "../../auth/AuthContext";

import {StatesAutoCompleteUseForm} from "../../../components/StatesAutoComplete";
import MainPageTitle from "../../../components/MainPageTitle";

import {
    LoadingOutlined,
    UserAddOutlined,
    DollarOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    ManOutlined,
    WomanOutlined,
    CalendarOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";

import {
    Button,
    Col,
    Divider,
    message,
    Row,
    Steps,
    DatePicker,
    Spin,
    Input,
    Typography,
    Select,
    Form,
    Space,
    Modal,
    Result,
    List,
} from "antd";

import {NavLink} from "react-router-dom";


const API_TOKEN = 'b4950c7d61f0976220c3754b81a78068690a9856';
const HEADERS_DATA = {headers: {'Authorization': `Bearer ${API_TOKEN}`, 'Content-Type': 'application/json',}}


const PRODUCT_ID = 1177;  // DOD Product === 1177 Provider ID hardcoded
const PRODUCT_NAME = 'PTSMC Kit';
const PRODUCT_PRICE = 540 * 100;  // $540 for DOD Kit
// const PRODUCT_PRICE = 100;  // $1 for test purchases
const PRODUCT_QTY = 1;
const DJANGO_API_URL = 'https://django-api.efunctional.com/api/v0'


// DEV key
// const stripePromise = loadStripe('pk_test_51NQX1MKbmzi38XjIgKLJeHOgtR8Sg6LKQaJxYTDmvC20zoUuRdBhDueZhdrJneVElw8qlGP2jKfCWqj8ioOoAWFY00fbuczKB2');

// LIVE key
const stripePromise = loadStripe('pk_live_51NQX1MKbmzi38XjIxakuqR9kjehxvOlJOnOsPNPqd9fvgkIbtqexLU11mjhUUkVP4LExn0FLZFTffkLKHEnUX0Iy00q7jY3QRr');
// ---------------- re-build this better way Stripe END ---------------- //

const STEPS = {
    ASSIGN_TO_PATIENT: 0,
    CHECKOUT: 1,
    SUPPLY_ORDER_SUCCEED: 2,
};

const phoneNumberMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (value = !x[2]
        ? x[1]
        : `(${x[1]}) ${x[2]}${x[3] ? "-" + x[3] : ""}`);
}

const dateOfBirthMask = (value) => {
    let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    return !x[2] ? x[1] : `${x[1]}-${x[2]}${x[3] ? '-' + x[3] : ''}`;
};

const CheckEmailInDB = props => {

    const {
        setEmailFromApi,
        emailInput,
        setEmailInput,
        setPatientData,
        setIsEmailValid
    } = props;

    const {authContext} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [patientList, setPatientList] = useState(false);

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const loadAllProviderPatients = async () => {
        try {
            setIsLoading(true);
            const response = await restAPI.get(`/patients/all/?web_provider__id=${authContext?.webProviderData?.id}`, authConfig);
            // const response = await restAPI.get(`/patients/all/`, authConfig);
            setPatientList(response?.data?.results);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const [timer, setTimer] = useState(null);
    const [modalIsShowing, setModalIsShowing] = useState(false);

    // Function to handle email input change
    const handleEmailInputChange = ({target: {value}}) => {
        setEmailInput(value);
        setIsEmailValid(null); // Reset validation status when input changes
        setEmailFromApi(null); // Reset email from API when input changes

        // Clear the previous timer
        if (timer) clearTimeout(timer);

        // Set a new timer for validation and API call
        setTimer(setTimeout(() => {
            if (emailValidationFunction(value)) {
                (async () => await checkEmailInApi(value))()
            }
        }, 500));
    };


    const emailValidationFunction = (email) => {
        // Email validation function
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    };


    // Function to make API request and check response
    const checkEmailInApi = async (email) => {
        try {
            // Replace with your API call
            const response = await restAPI.get(`/users/?email=${email}`, HEADERS_DATA)
            setEmailFromApi(response.data.results[0]);
            // Check if the response length is exactly 1
            setIsEmailValid(response.data.results.length === 1);

            const patientData = await restAPI.get(`/patients/all/?email=${email}`, HEADERS_DATA)
            setPatientData(patientData.data.results[0]);

        } catch (error) {
            console.error('Error checking email:', error);
            setIsEmailValid(false);
        }
    };


    const choosePatient = email => {
        handleEmailInputChange({target: {value: email}});
        // setIsEmailValid(true);
        // setEmailFromApi(email);
        setModalIsShowing(false);

    }

    // Clear timer on the component unmount
    useEffect(() => {
        (async () => {
            await loadAllProviderPatients();
        })();

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [timer]);

    return (
        <Row style={{marginTop: 40}}>

            <Col lg={20} xs={24}>
                <Form>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter email',
                            },
                            {
                                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                message: 'Please enter valid email',
                            },
                        ]}
                    >
                        <Row gutter={16}>
                            <Col xs={20}>
                                <Input
                                    allowClear={true}
                                    placeholder="Please enter email"
                                    value={emailInput}
                                    onChange={handleEmailInputChange}
                                />
                            </Col>

                            <Col xs={4}>
                                <Button
                                    onClick={() => setModalIsShowing(true)}
                                    icon={<UnorderedListOutlined/>}/>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Col>

            <Modal
                onCancel={() => setModalIsShowing(false)}
                destroyOnClose={true}
                title="My clients"
                open={modalIsShowing}
                footer={null}
                keyboard={true}
                closable={true}
            >
                <Typography.Paragraph>
                    Please select a client from the list for create an order for them.
                </Typography.Paragraph>

                <List
                    style={{height: '55vh', overflow: 'auto'}}
                    size={'small'}
                    dataSource={patientList}
                    renderItem={
                        (patient, index) =>
                            <List.Item
                                key={index}
                                extra={<Button
                                    size={"small"}
                                    type={"primary"}
                                    icon={<DoubleRightOutlined/>}
                                    onClick={() => choosePatient(patient.email)}>Choose</Button>}>
                                <List.Item.Meta
                                    title={`${patient.first_name} ${patient.last_name}`}
                                    description={patient.email}
                                />
                            </List.Item>
                    }
                />


            </Modal>
        </Row>
    )
        ;
};

const AssignToPatientComponent = props => {
    /*  Step 2  */

    const {
        setIsLoading,
        isLoading,
        moveToCheckoutStep,
        setNewPatientData,
        newPatientData,
    } = props;

    const [form] = Form.useForm();

    const [emailInput, setEmailInput] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [emailFromApi, setEmailFromApi] = useState(null);
    const [patientData, setPatientData] = useState(null);

    const [dateValue, setDateValue] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleManualInput = (e) => {
        const maskedValue = dateOfBirthMask(e.target.value);
        setDateValue(maskedValue);
        form.setFieldValue('date_of_birth', maskedValue);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCalendarChange = (date) => {
        setDateValue(date ? dayjs(date).format('MM-DD-YYYY') : '');
        setIsModalVisible(false);
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const values = await form.validateFields();

            await setNewPatientData({...values, email: emailInput, existedPatient: false}); // Add email to the patient data
            await moveToCheckoutStep();
        } catch (error) {
            console.error('Form validation failed:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const prepareDataForExistedPatient = async () => {
        try {
            await setNewPatientData({...patientData, existedPatient: true});
            await moveToCheckoutStep();
        } catch (error) {
            console.error('[prepareDataForExistedPatient] ::: ', error);
        }
    };

    const renderCheckComponent = () => {
        // Render component for checking email in the system.

        // If email in the system, we can't create a new patient account -> show the message
        if (isEmailValid === true && patientData && patientData?.email) {
            return <Row>
                <Col>
                    Patient with this email already in system, and you can make an order for him
                </Col>

                <Col lg={24} xs={24}>
                    <Form.Item>
                        <Space>
                            <Button
                                loading={isLoading}
                                style={{marginTop: 30}}
                                onClick={prepareDataForExistedPatient}
                                type={'primary'}
                                icon={<DoubleRightOutlined/>}
                            >
                                Go to checkout
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>

            </Row>
        }

        // If email not in the system, we can create a new patient account -> show the form
        if (isEmailValid === false) {
            return <Form
                name={'assign-to-patient'}
                form={form}
                style={{marginTop: 30}}
                layout="vertical"
                onFinish={handleSubmit}
                autoComplete="off"
                initialValues={newPatientData || {}} // Setting default values
            >
                <Row gutter={16}>
                    <Col lg={8} xs={24}>
                        <Form.Item
                            name="first_name"
                            label="First name"
                            rules={[{
                                required: true, message: 'Please input first name',
                            },]}
                        >
                            <Input placeholder={'First name'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={8} xs={24}>
                        <Form.Item
                            name="last_name"
                            label="Last name"
                            rules={[{required: true, message: ''}]}
                        >
                            <Input placeholder={'Last name'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={8} xs={24}>
                        <Form.Item
                            name="gender"
                            label="Sex"
                            rules={[{required: true, message: "Can't be empty"}]}
                        >
                            <Select
                                placeholder="Please choose a sex"
                                defaultOpen={0}
                                style={{width: '100%'}}
                                options={[{label: "Male", value: "M"}, {label: "Female", value: "F"},]}
                            />
                        </Form.Item>
                    </Col>


                    <Col lg={8} xs={24}>
                        <Form.Item
                            name="date_of_birth"
                            label="Date of Birth"
                            rules={[{required: true, message: 'Please enter your date of birth'}]}
                        >
                            <Input
                                suffix={<CalendarOutlined onClick={showModal}/>}
                                value={dateValue}
                                onChange={handleManualInput}
                                placeholder="MM-DD-YYYY"
                            />
                            <Modal
                                title="Select Date"
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                footer={null}
                            >
                                <DatePicker
                                    format="MM-DD-YYYY"
                                    value={dateValue ? dayjs(dateValue, 'MM-DD-YYYY') : null}
                                    onChange={handleCalendarChange}
                                    style={{width: '100%'}}
                                />
                            </Modal>
                        </Form.Item>
                    </Col>


                    <Col lg={8} xs={24}>
                        <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[
                                {required: true, message: 'Please enter phone',},
                                {
                                    pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/,
                                    message: 'Please enter valid phone number',
                                }
                            ]}
                        >
                            <Input
                                onChange={({target: {value}}) => {
                                    const prettyPhoneNumber = phoneNumberMask(value);

                                    form.setFieldValue('phone', prettyPhoneNumber);
                                }}
                                placeholder="Please enter phone"
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={10} xs={24}>
                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[{
                                required: true, message: 'Please input an address',
                            },]}
                        >
                            <Input placeholder={'Address'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={6} xs={24}>
                        <Form.Item
                            name="city"
                            label="City"
                            rules={[{required: true, message: 'Please input a city'}]}
                        >
                            <Input placeholder={'City'}/>
                        </Form.Item>
                    </Col>


                    <Col lg={4} xs={24}>
                        <StatesAutoCompleteUseForm form={form} isNyStateIncluded={true}/>
                    </Col>


                    <Col lg={4} xs={24}>
                        <Form.Item
                            name="postcode"
                            label="Zip code"
                            rules={[{required: true, message: 'Please input a zip'}]}
                        >
                            <Input placeholder={'Zip code'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={24} xs={24}>
                        <Form.Item>
                            <Space>
                                <Button
                                    style={{marginTop: 30}}
                                    onClick={handleSubmit}
                                    type={'primary'}
                                    icon={<DoubleRightOutlined/>}
                                >
                                    Go to checkout
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        }

        // If email in the system, we can't create a new patient account -> show the message
        if (isEmailValid === true && !patientData && !patientData?.email) {
            return <p style={{color: 'red'}}>
                Can't create an order for this email
            </p>
        }

        return null;
    }

    useEffect(() => {
        if (newPatientData) {
            form.setFieldsValue(newPatientData);
        }
    }, [newPatientData, form]);

    return <>
        <Typography.Title level={4} style={{fontWeight: 400, marginTop: 30}}>
            Assign to patient
        </Typography.Title>

        <Typography.Paragraph>
            Please enter patient email to assign the order to him.
        </Typography.Paragraph>

        <CheckEmailInDB
            setPatientData={setPatientData}
            emailInput={emailInput}
            setEmailInput={setEmailInput}
            setEmailFromApi={setEmailFromApi}
            isEmailValid={isEmailValid}
            setIsEmailValid={setIsEmailValid}
        />

        {renderCheckComponent()}
    </>;
};

const CheckoutComponent = ({
                               newPatientData,
                               moveToPatientCreatingStep,
                               payAndSaveOrderHandler,
                               payLaterAndSaveSupplyOrderHandler,
                               isLoading,
                           }) => {
    /*  Step 3  */

    const getPatientAddress = () => {
        // Handler for getting a patient address for couple cases.
        //  .existedPatient - it's a code helper field to understand if a patient is already in the system.
        let address;
        if (newPatientData?.existedPatient) {
            const {shipping_addresses} = newPatientData;
            address = shipping_addresses[0]?.address_1;
            address += shipping_addresses[0]?.address_2 ? `, ${shipping_addresses[0]?.address_2}` : '';
            address += shipping_addresses[0]?.city ? `, ${shipping_addresses[0]?.city}` : '';
            address += shipping_addresses[0]?.state ? `, ${shipping_addresses[0]?.state}` : '';
            address += shipping_addresses[0]?.postcode ? `, ${shipping_addresses[0]?.postcode}` : '';
        } else {
            const {address: address_1, city, state, postcode} = newPatientData;
            address = `${address_1}, ${city ? `, ${city}` : ''}${state ? `, ${state}` : ''}${postcode ? `, ${postcode}` : ''}`;
        }
        return address;
    }

    const getPatientGender = () => {
        // Handler for getting a patient gender for render the markup.
        const checkGenderIcon = genderString => {
            // Helper function for getting a gender icon
            if (genderString.toLowerCase() === 'm' || genderString.toLowerCase() === "male") return <ManOutlined/>;
            if (genderString.toLowerCase() === 'f' || genderString.toLowerCase() === "female") return <WomanOutlined/>;
            return null;
        }

        const {gender, first_name: firstName, last_name: lastName} = newPatientData;
        return <>{firstName} {lastName} {checkGenderIcon(gender)}</>;
    }

    return <Row>

        <Col xxl={24}>
            <Typography.Title level={4} style={{fontWeight: 400, marginTop: 30}}>
                Order confirmation and payment
            </Typography.Title>
        </Col>

        <Col xxl={24}>
            <Row>
                {newPatientData && <>
                    <Col xxl={24} style={{marginBottom: 30}}>
                        <Typography.Title level={5}>
                            Patient information:
                        </Typography.Title>

                        Full name:
                        <Divider type={'vertical'}/>
                        {getPatientGender()}
                        <br/>

                        Date of birth:
                        <Divider type={'vertical'}/>
                        {dayjs(newPatientData.date_of_birth).format('MM-DD-YYYY')}
                        <br/>


                        Email:
                        <Divider type={'vertical'}/>
                        {newPatientData.email}
                        <br/>

                        Phone:
                        <Divider type={'vertical'}/>
                        {newPatientData.phone}
                        <br/>


                        Address:
                        <Divider type={'vertical'}/>
                        {getPatientAddress()}
                    </Col>


                    <Col xxl={24}>
                        <Typography.Title level={5}>Shipping information:</Typography.Title>

                        <Typography.Text>
                            Order receiver:
                            <Divider type={'vertical'}/>
                            {newPatientData.first_name} {newPatientData.last_name}
                        </Typography.Text>

                        <br/>

                        <Typography.Text>
                            Delivery address:
                            <Divider type={'vertical'}/>
                            {getPatientAddress()}
                        </Typography.Text>


                    </Col>
                </>}

            </Row>
        </Col>

        <Col lg={24} xs={24}>
            <Form.Item>
                <Space>
                    <Button
                        style={{marginTop: 30}}
                        onClick={moveToPatientCreatingStep}
                        type={'dashed'}
                        icon={<DoubleLeftOutlined/>}
                    ></Button>

                    <Button
                        loading={isLoading}
                        disabled={isLoading}
                        style={{marginTop: 30}}
                        onClick={payAndSaveOrderHandler}
                        type={'primary'}
                        icon={<DoubleRightOutlined/>}
                    >
                        Pay now
                    </Button>

                    <Button
                        loading={isLoading}
                        disabled={isLoading}
                        style={{marginTop: 30}}
                        onClick={payLaterAndSaveSupplyOrderHandler}
                        type={'primary'}
                        icon={<DoubleRightOutlined/>}
                    >
                        Pay later
                    </Button>
                </Space>
            </Form.Item>
        </Col>
    </Row>
};

const NewOrder = () => {
    /* New product form for WebProvider */

    const [messageApi, contextHolder] = message.useMessage();
    const {authContext} = useContext(AuthContext); // auth data from current session
    const [isLoading, setIsLoading] = useState(false);

    /* Stepper brains */
    const [currentStepperStep, setCurrentStepperStep] = useState(STEPS.ASSIGN_TO_PATIENT);
    const [newPatientData, setNewPatientData] = useState(null);

    const moveToPatientCreatingStep = () => {
        setNewPatientData(null);
        setCurrentStepperStep(STEPS.ASSIGN_TO_PATIENT);
    }

    const returnToChooseTheProduct = () => {
        setCurrentStepperStep(STEPS.CHOOSE_PRODUCT);
    }

    const moveToCheckoutStep = () => {
        setCurrentStepperStep(STEPS.CHECKOUT);
    }

    const moveToSuccessResultStep = () => {
        setCurrentStepperStep(STEPS.SUPPLY_ORDER_SUCCEED);
    }


    const resetThePage = () => {
        setNewPatientData(null);
        setCurrentStepperStep(STEPS.ASSIGN_TO_PATIENT);
    }
    /* --- */

    const payAndSaveOrderHandler = async () => {
        /* Current request sends data to the Stripe  */
        setIsLoading(true);

        try {
            const preparedAddressDataObjects = () => {
                // Handler for getting a patient address for couple cases.
                //  .existedPatient - it's a code helper field to understand if a patient is already in the system.
                if (newPatientData?.existedPatient) {
                    const {shipping_addresses} = newPatientData;
                    const [defaultShippingAddress,] = shipping_addresses;
                    const address = defaultShippingAddress?.address_1 + (defaultShippingAddress?.address_2 ? `, ${defaultShippingAddress?.address_2}` : '');
                    const {city, state, postcode} = defaultShippingAddress;
                    return {address, city, state, postcode}
                }
                const {address, city, state, postcode} = newPatientData || {};
                return {address, city, state, postcode}
            }

            const preparedGenderValue = () => {
                // Handler for getting a patient gender.
                const {gender} = newPatientData || {};

                const getGenderString = genderString => {
                    // Helper function for getting a gender icon
                    if (gender.toLowerCase() === 'm' || gender.toLowerCase() === "male") return 'Male';
                    if (gender.toLowerCase() === 'f' || gender.toLowerCase() === "female") return 'Female';
                    return null;
                }

                return getGenderString(gender)
            }

            // Prepare patient data for the request
            const patientData = {
                ...newPatientData,
                ...preparedAddressDataObjects(),
                gender: preparedGenderValue(),
                date_of_birth: dayjs(newPatientData.date_of_birth).format('YYYY-MM-DD'),
            }

            // Check if webProvider ID is defined
            if (!authContext?.webProviderData?.id) throw new Error('WebProvider ID is not defined');

            // Prepare webProvider ID for the request
            const {id: webProviderId} = authContext?.webProviderData;


            // Prepare data for the request to the API for creating a Stripe session.
            const orderData = {
                name: PRODUCT_NAME,
                productId: PRODUCT_ID,
                price: PRODUCT_PRICE,
                quantity: PRODUCT_QTY,
                patientData,
                webProviderId
            }

            // // Debug data before sending to the Stripe
            // console.log("orderData", orderData)
            // return;

            // const response = await fetch('http://0.0.0.0:8080/api/v0/stripe/checkout-web-provider-order/', {
            const response = await fetch(`${DJANGO_API_URL}/stripe/checkout-web-provider-order/`, {
                method: 'POST', ...HEADERS_DATA, body: JSON.stringify(orderData),
            });

            const data = await response.json();
            if (data.error) {
                console.error(data.error);
                return;
            }

            const stripe = await stripePromise;
            const result = await stripe.redirectToCheckout({sessionId: data.id});

            if (result.error) {
                console.error(result.error.message);
            }

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }


    const payLaterAndSaveSupplyOrderHandler = async () => {
        /* Current request sends data to the Stripe  */
        setIsLoading(true);

        try {
            const preparedAddressDataObjects = () => {
                // Handler for getting a patient address for couple cases.
                //  .existedPatient - it's a code helper field to understand if a patient is already in the system.
                if (newPatientData?.existedPatient) {
                    const {shipping_addresses} = newPatientData;
                    const [defaultShippingAddress,] = shipping_addresses;
                    const address = defaultShippingAddress?.address_1 + (defaultShippingAddress?.address_2 ? `, ${defaultShippingAddress?.address_2}` : '');
                    const {city, state, postcode} = defaultShippingAddress;
                    return {address, city, state, postcode}
                }
                const {address, city, state, postcode} = newPatientData || {};
                return {address, city, state, postcode}
            }

            const preparedGenderValue = () => {
                // Handler for getting a patient gender.
                const {gender} = newPatientData || {};

                const getGenderString = genderString => {
                    // Helper function for getting a gender icon
                    if (gender.toLowerCase() === 'm' || gender.toLowerCase() === "male") return 'Male';
                    if (gender.toLowerCase() === 'f' || gender.toLowerCase() === "female") return 'Female';
                    return null;
                }

                return getGenderString(gender)
            }

            // Prepare patient data for the request
            const patientData = {
                ...newPatientData,
                ...preparedAddressDataObjects(),
                gender: preparedGenderValue(),
                date_of_birth: dayjs(newPatientData.date_of_birth).format('YYYY-MM-DD'),
            }

            // Check if webProvider ID is defined
            if (!authContext?.webProviderData?.id) throw new Error('WebProvider ID is not defined');

            // Prepare webProvider ID for the request
            const {id: webProviderId} = authContext?.webProviderData;


            // Prepare data for the request to the API for creating a Stripe session.
            const orderData = {
                name: PRODUCT_NAME,
                productId: PRODUCT_ID,
                price: PRODUCT_PRICE,
                quantity: PRODUCT_QTY,
                patientData,
                webProviderId
            }

            // // Debug data before sending to the Stripe
            // console.log("orderData", orderData)
            // return;

            // const response = await fetch('http://0.0.0.0:8080/api/v0/stripe/checkout-web-provider-order/', {
            const response = await fetch(`${DJANGO_API_URL}/stripe/create-supply-order/`, {
                method: 'POST', ...HEADERS_DATA, body: JSON.stringify(orderData),
            });

            const data = await response.json();
            if (data.error) {
                console.error(data.error);
                return;
            }

            moveToSuccessResultStep()

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        {contextHolder}

        <Row gutter={[24, 0]}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>Order the PTSMC Kit</MainPageTitle>
            </Col>

            <Col xs={24} lg={24} xl={12} xxl={22}>
                <Steps
                    hidden={currentStepperStep === STEPS.SUPPLY_ORDER_SUCCEED}
                    status={'process'}
                    type={'default'}
                    responsive={true}
                    current={currentStepperStep}
                    items={[
                        {
                            title: <Typography.Text type={'secondary'}>Assign to patient</Typography.Text>,
                            icon: <UserAddOutlined/>,
                        },
                        {
                            title: <Typography.Text type={'secondary'}>Checkout</Typography.Text>,
                            icon: <DollarOutlined/>,
                        },
                    ]}
                />

                {currentStepperStep === STEPS.ASSIGN_TO_PATIENT && <AssignToPatientComponent
                    returnToChooseTheProduct={returnToChooseTheProduct}
                    moveToCheckoutStep={moveToCheckoutStep}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    newPatientData={newPatientData}
                    setNewPatientData={setNewPatientData}
                />}

                {currentStepperStep === STEPS.CHECKOUT && <CheckoutComponent
                    moveToPatientCreatingStep={moveToPatientCreatingStep}
                    moveToCheckoutStep={moveToCheckoutStep}
                    payAndSaveOrderHandler={payAndSaveOrderHandler}
                    payLaterAndSaveSupplyOrderHandler={payLaterAndSaveSupplyOrderHandler}
                    isLoading={isLoading}
                    newPatientData={newPatientData}
                    moveToSuccessResultStep={moveToSuccessResultStep}
                />}

                {currentStepperStep === STEPS.SUPPLY_ORDER_SUCCEED && <Result
                    style={{paddingTop: 160}}
                    status="success"
                    title="The order was successfully saved!"
                    subTitle="Thank you for your choice and cooperation!"
                    extra={[
                        <Space>
                            <Button onClick={resetThePage} type="primary" key="console">
                                Make another order
                            </Button>

                            <NavLink to={'/'}>
                                <Button type="dashed" key="console">
                                    Go to main page
                                </Button>
                            </NavLink>
                        </Space>
                    ]}
                />
                }

            </Col>
        </Row>
    </Spin>
}

export default NewOrder;