import restAPI from "../../../features/restAPI";
import React, {useState, useEffect} from "react";
import dayjs from "dayjs";
import {
    FilePdfOutlined,
    LinkOutlined,
    ProfileOutlined,
    CloseOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {Button, Space, Modal, Alert, Tooltip, Row, Col, Typography, Divider, Select, Form, Drawer, Spin} from "antd";
import {LOGO_EFUNC, VIT_LAB} from '../../../assets/images';
import styled from "styled-components";
import {FinalizedKitsTable} from "./styled-components/FinalizedTable"

const ElaborateReportModal = styled(Modal)`
    .ant-modal-content {
        padding: 0 !important;
    }
`


const KitsTable = ({kitsList, isLoading, authConfig, authContext}) => {
    /* Table with all kits  */


    const pagination = {position: ["bottom"],}

    const openGeneralPdf = () => {
        /* Default PDF-file with explanations for FS and FE test-kits */

        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = '/static_files/56%20pages%20report.pdf';
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    };

    const openPersonalPdf = async (kitID, kitBarcode) => {
        /* Personal PDF-file with explanations for FS and FE test-kits.
           Works with FE, FS, MN test kits.
           Each barcode has its own PDF-file with unique content.
           PDF-url formula: f"{file_name_without_extension}_{kit.id}.pdf" */
        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = `/mediafiles/patients-pdf-reports/${kitBarcode}_${kitID}.pdf`;
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    }

    const openECLPdf = async (kitID, kitBarcode) => {
        /* Personal PDF-file with explanations for FS and FE test-kits.
           Works with FE, FS, MN test kits.
           Each barcode has its own PDF-file with unique content.
           PDF-url formula: f"{file_name_without_extension}_{kit.id}.pdf" */
        const prefix = "AR_ECL";
        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = `/mediafiles/patients-pdf-reports/${prefix}_${kitBarcode}_${kitID}.pdf`;
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    }

    // Declined kit  modal
    const [openDeclinedModal, setOpenDeclinedModal] = useState(false);

    const showDeclinedModal = (currentKit) => {
        setSelectedKIT({...currentKit})
        setOpenDeclinedModal(true);
    };

    const handleDeclinedModalOk = () => {
        handleDeclinedModalCancel();
    };

    const handleDeclinedModalCancel = () => {
        // console.log('Clicked cancel button');
        setOpenDeclinedModal(false);
    };


    // table column settings
    const columns = [

        {
            title: 'Barcode', dataIndex: 'barcode', responsive: ['xs', 'lg'],
        },

        {
            title: 'Test', dataIndex: 'test', responsive: ['lg'],
        },

        {
            title: 'Status', dataIndex: 'status', responsive: ['xs', 'lg'],
        },

        {
            title: 'Activation Date',
            dataIndex: 'dateActivated',
            render: date => <>{dayjs(date).format("YYYY-MM-DD")}</>,
            responsive: ['lg',],
        },

        {
            title: 'Results', dataIndex: 'created', render: (finalDate, currentKit) =>


                <Space key={finalDate}>


                    {currentKit.status === 'LabDecline'

                        ? <Tooltip placement="left" title={'Reason explanation'} zIndex={10}>
                            <Button
                                danger
                                color={'danger'}
                                icon={<WarningOutlined/>}
                                onClick={() => showDeclinedModal(currentKit)}
                            />
                        </Tooltip>

                        : currentKit.status === 'MDDecline'

                            ? <Tooltip placement="left" title={'Reason explanation'} zIndex={10}>
                                <Button
                                    danger
                                    // type={'primary'}
                                    icon={<WarningOutlined/>}
                                    onClick={() => showDeclinedModal(currentKit)}
                                />
                            </Tooltip>

                            : currentKit.test === "MN" && currentKit.status === 'Final'

                                ? <Space>

                                    <Tooltip placement="left" title={'Vitamins list'}>
                                        <Button icon={<ProfileOutlined/>}
                                                onClick={() => showDrawerHandler(currentKit)}/>
                                    </Tooltip>

                                    <Tooltip placement="left" title={'Full report'} zIndex={10}>
                                        <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>
                                    </Tooltip>

                                    <Tooltip placement="right" title={'Personal report'} zIndex={10}>
                                        <Button
                                            icon={<FilePdfOutlined/>}
                                            onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                        />
                                    </Tooltip>

                                </Space>

                                : ["AM", "AF"].includes(currentKit.test) && currentKit.status === 'Final'

                                    ? <Space>

                                        <Tooltip placement="top" title={'ECL report'} zIndex={10}>
                                            <Button
                                                type={'primary'}
                                                icon={<FilePdfOutlined/>}
                                                onClick={() => openECLPdf(currentKit?.id, currentKit?.barcode)}
                                            />
                                        </Tooltip>

                                        <Tooltip placement="top" title={'IGS report'} zIndex={10}>
                                            <Button
                                                icon={<FilePdfOutlined/>}
                                                onClick={() => openPersonalPdf(currentKit?.id, currentKit?.barcode)}
                                            />
                                        </Tooltip>

                                        <Tooltip placement="top" title={'Vitamins list'}>
                                            <Button icon={<ProfileOutlined/>}
                                                    onClick={() => showDrawerHandler(currentKit)}
                                            />
                                        </Tooltip>

                                    </Space>


                                    : (currentKit.test === "FS" || currentKit.test === "FE") && currentKit.status === 'Final'

                                        ? <Space>
                                            <Tooltip placement="left" title={'General info'} zIndex={10}>
                                                <Button
                                                    icon={<FilePdfOutlined/>}
                                                    // onClick={() => showModal(currentKit)}
                                                    onClick={openGeneralPdf}
                                                />
                                            </Tooltip>

                                            <Tooltip placement="right" title={'Personal report'} zIndex={10}>
                                                <Button
                                                    icon={<FilePdfOutlined/>}
                                                    onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                                />
                                            </Tooltip>
                                        </Space>

                                        : currentKit.test !== "MN" && currentKit.test !== "FE" && currentKit.status === 'Final' ?

                                            <Tooltip placement="left" title={'Full report'} zIndex={10}>
                                                <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>
                                            </Tooltip> : <span>Pending</span>


                    }
                </Space>, responsive: ['xs', 'md',],
        },

    ];

    // modal and iframe stuf
    const [open, setOpen] = useState(false);
    const [selectedKIT, setSelectedKIT] = useState(null);
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [iframeLink, setIframeLink] = useState(null);
    const [reportError, setReportError] = useState(null);

    // vitamin drawer
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    const [isCreateOrderLoading, setIsCreateOrderLoading] = useState(false);
    const [kind, setKind] = useState(null);  // kind of vitamins
    const [daysSupply, setDaysSupply] = useState(null);  // vitamins days supply


    const showModal = (currentKit) => {
        setSelectedKIT({...currentKit})
        setOpen(true);
    };

    const handleOk = () => {
        handleCancel();
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
        setSelectedKIT(null);
        setIframeLink(null);
        setReportError(null);
    };

    const getReportLink = async () => {
        /* Elaborate Report */
        const {id, patient, to_date, npi} = selectedKIT;

        const data = {
            "kitID": id,
            "patientID": patient,
            "doctorNPI": npi,  // for Patient, we can check only Patient info.
            to_date
        };

        try {
            setIsReportLoading(true);

            const response = await restAPI.post(`/results/get-report-link/`, data, authConfig)

            // console.log(response.data.secureURL);

            setIframeLink(response.data.secureURL);

        } catch (error) {

            if (error.hasOwnProperty('response')) {
                setReportError(error.response.data.error);
            }

            console.log(error);

        } finally {
            setIsReportLoading(false);
        }

    }


    useEffect(() => {
        if (selectedKIT && open) {
            (async () => {
                await getReportLink();
            })();
        }
    }, [open])


    const showDrawerHandler = currentKIT => {
        setSelectedKIT(currentKIT);
        setIsDrawerOpened(true);
    }

    const onDrawerCloseHandler = () => {
        console.log('Clicked cancel button');
        setIsDrawerOpened(false);
        setSelectedKIT(null);
        setKind(null);
        setDaysSupply(null);
    }

    const kindChangeHandler = state => {
        console.log(state);
        setKind(state);
    }

    const daysSupplyChangeHandler = state => {
        console.log(state);
        setDaysSupply(state);
    }


    const makeNewVitaminLabsOrder = async action => {
        /*
        -> make request for creating a new order
        -> after api successfully answer
        -> redirect user to `VitaminLabs` with orderID in params */

        // console.log(action);
        // console.log(selectedKIT);

        const {first_name, last_name, id} = selectedKIT?.patient_details;

        // Prepare data for VitaminLabs
        const data = {
            "partner_uuid": selectedKIT?.id,
            "kind": kind,
            "days_supply": parseInt(daysSupply),
            "web_provider_id": parseInt(authContext?.webProviderData?.id),
            "meta": {
                first_name, last_name, "efunctionalID": id,
            }
        }

        try {

            setIsCreateOrderLoading(true);

            // Save order to Efunctional DB
            const response = await restAPI.post('igs-vitamins/make-vitaminlab-order/', data, authConfig);

            if (action === 'buy') {
                // window.open(`https://wp-staging.getvitaminlab.com/efunctional-portal/?buy_now=true&formula_id=${response.data.orderID}`)
                window.open(`https://getvitaminlab.com/efunctional-portal/?buy_now=true&formula_id=${response.data.orderID}`)
            } else if (action === 'modify') {
                // window.open(`https://wp-staging.getvitaminlab.com/efunctional-portal/?formula_id=${response.data.orderID}`)
                window.open(`https://getvitaminlab.com/efunctional-portal/?formula_id=${response.data.orderID}`)
            }

        } catch (error) {

            console.log(error);

            // TODO make errors alerts and show user problem

        } finally {
            setIsCreateOrderLoading(false);
            onDrawerCloseHandler();  // Destroy child's state too
        }

    }

    // console.log(authContext?.providerData)

    console.log(authContext?.webProviderData)

    return <>

        <Drawer
            destroyOnClose={true}
            bodyStyle={{width: '100%'}}
            size={'large'}
            placement={'right'}
            closeIcon={<CloseOutlined/>}
            width={window.innerWidth > 1200 ? '38%' : '92%'}
            closable={window.innerWidth <= 1200}
            onClose={onDrawerCloseHandler}
            open={isDrawerOpened}
        >
            <Row gutter={[12, 12]}>

                <Col span={'24'}>
                    <Row justify={'space-between'}>
                        <Col span={12}>
                            <img src={LOGO_EFUNC} alt="EFunctional logo" style={{width: '45px'}}/>
                        </Col>

                        <Col span={12}>
                            <img src={VIT_LAB} alt="VitaminLab logo" style={{width: '50%', float: 'right'}}/>
                        </Col>
                    </Row>
                </Col>

                <Col span={'24'}>
                    <Typography.Title
                        style={{
                            fontSize: 21,
                            fontWeight: 400,
                            textTransform: 'uppercase',
                            margin: '30px 0',
                            fontFamily: 'Montserrat',
                            color: '#353c4e'
                        }}>
                        Micronutrient Order Form
                    </Typography.Title>
                    <Divider style={{margin: '-30px 0 20px 0'}}/>
                </Col>

                <Col span={'24'}>
                    <Typography.Title style={{fontSize: 14, fontWeight: 400, textTransform: 'uppercase'}}>
                        <strong>ORDER OPTIONS</strong>
                    </Typography.Title>
                </Col>

                <Col span={'24'}>
                    <Form.Item name="supply" label="Supply">
                        <Select
                            value={kind}
                            placeholder={'How much days?'}
                            style={{width: '200px'}}
                            onChange={daysSupplyChangeHandler}
                            options={[{value: '90', label: '90 Days',}, {
                                value: '120', label: '120 Days',
                            }, {value: '180', label: '180 Days',},]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="doseType" label="Dose Type">
                        <Select
                            value={daysSupply}
                            placeholder={'What kind?'}
                            style={{width: '200px'}}
                            onChange={kindChangeHandler}
                            options={[{value: 'powder', label: 'Powder',}, {
                                value: 'capsules', label: 'Veggie Capsules',
                            },]}
                        />
                    </Form.Item>

                </Col>

                <Divider style={{margin: '-20px 0 20px 0'}}/>

                <Col span={24}>
                    <Typography.Title style={{fontSize: 14, fontWeight: 400, textTransform: 'uppercase'}}>
                        <strong>YOUR CUSTOM SUPPLEMENTS</strong>
                    </Typography.Title>
                </Col>

                <Col span={24}>
                    {selectedKIT?.vitamins.map(vitamin => <Row style={{fontFamily: 'Montserrat', margin: '0 0 7px 0'}}>
                        <Col span={12} key={vitamin.id}>
                            <div>{vitamin.suggested}</div>
                        </Col>

                        <Col span={12}>
                            <strong>{vitamin.calc} {vitamin.frequency}</strong>
                        </Col>
                    </Row>)}
                </Col>


                <Divider/>


                <Col span={10} offset={8}>
                    <Space>
                        <Button
                            onClick={async () => await makeNewVitaminLabsOrder('buy')}
                            disabled={isCreateOrderLoading || kind === null || daysSupply === null}
                        >
                            Buy Now
                        </Button>

                        <Button
                            type={'primary'}
                            onClick={async () => await makeNewVitaminLabsOrder('modify')}
                            disabled={isCreateOrderLoading || kind === null || daysSupply === null}
                        >
                            Add Ingredients
                        </Button>
                    </Space>
                </Col>

            </Row>
        </Drawer>


        {/*----------divider-----------*/}


        {/*Elaborate Report Here   ***--- START ---*** */}
        <ElaborateReportModal
            centered={true}
            destroyOnClose={true}
            closable={true}
            width={reportError ? '20%' : '95%'}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
        >

            {reportError &&
                <Spin spinning={isReportLoading}>
                    <Row justify={"center"} gutter={[24, 24]} style={{padding: "20px"}}>
                        <Col>
                            <Alert style={{margin: "30px 0 0 0", textAlign: "center"}} message={reportError}/>
                        </Col>
                    </Row>
                </Spin>
            }

            {iframeLink &&
                <iframe
                    width={'100%'}
                    height={'790px'}
                    loading={isReportLoading}
                    title={'Elaborate report'}
                    src={iframeLink}
                />
            }


            <Row justify={"center"} style={{padding: "0 0 20px 0"}}>
                <Button
                    icon={reportError ? null : <CloseOutlined/>}
                    size={'small'}
                    type={reportError ? 'primary' : 'default'}
                    onClick={handleCancel}
                >
                    {reportError ? 'Okay' : 'Close report'}
                </Button>
            </Row>

        </ElaborateReportModal>
        {/*Elaborate Report Here   ***--- END ---*** */}


        <FinalizedKitsTable
            loading={isLoading}
            rowKey={'myTable'}
            sticky={true}
            size={'small'}
            columns={Boolean(authContext?.webProviderData?.be_able_to_see_patients_results) ? columns : columns.slice(0, 4)}
            dataSource={kitsList}
            pagination={pagination}
            scroll={false}
        />

    </>
}

export default KitsTable;