/*
* This file it's main router config. All urls and components for this urls, texts for title and others in the future.
* Use this file firstly for creating new page.
* */
import NotFoundPage from "../pages/base/NotFoundPage";
import Login from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import Logout from "../pages/auth/Logout";

import {AuthRequired} from "../pages/auth/AuthProvider";
import Dashboard from "../pages/application/Dashboard";
import EmployeesManagementEmails from "../pages/application/EmployeesManagementEmails";
import MyInformation from "../pages/application/MyInformation";
import NewOrder from "../pages/application/NewOrder";
import MyResults from "../pages/application/MyResults"; // TODO: remove this page
import ActivateKit from "../pages/application/ActivateKit";

import PaymentSuccessful from "../pages/stripe/PaymentSuccessful";
import PaymentCancel from "../pages/stripe/PaymentCancel";

import NewDODOrder from "../pages/application/NewDODOrder";
import MyPatients from "../pages/application/MyPatients";
import History from "../pages/application/History";

const notAuthPages = {

    'login': {
        url: '/login',
        title: 'Login',
        jsx: <Login/>,
    },

    'login1': {
        url: '/login/',
        title: 'Login',
        jsx: <Login/>,
    },

    'loginDefault': {
        url: '/',
        title: 'Login',
        jsx: <Login/>,
    },

    'resetPasswordDefault': {
        url: '/reset-password/',
        title: 'Reset password',
        jsx: <ResetPassword/>,
    },

    'resetPassword': {
        url: '/reset-password',
        title: 'Reset password',
        jsx: <ResetPassword/>,
    },

    'logout': {
        url: '/logout',
        title: 'Logout',
        jsx: <Logout/>,
    },


    // Using it for not auth guys who go to different pages
    'notFoundPageForNotAuth': {
        url: '*',
        title: 'Page not found. 404',
        jsx: <NotFoundPage/>,
    },
};

const authPages = {

    'ordersHistory': {
        url: '/orders-history',
        title: 'Orders History',
        jsx: <AuthRequired><History/></AuthRequired>,
    },

    // tech loan - need to fix it for others providers
    'myPatients': {
        url: '/my-patients',
        title: 'My Patients',
        jsx: <AuthRequired><MyPatients/></AuthRequired>,
    },

    // tech loan - need to fix it for other web providers
    'newDodOrder': {
        url: '/new-dod-order',
        title: 'New DOD Order',
        jsx: <AuthRequired><NewDODOrder/></AuthRequired>,
    },

    'stripeSuccessPayment': {
        url: '/payment-successful',
        title: 'The payment was successful!',
        jsx: <PaymentSuccessful/>,
    },

    'stripeCancelPayment': {
        url: '/payment-canceled',
        title: 'Payment has been cancelled!',
        jsx: <PaymentCancel/>,
    },

    'notFoundPage': {
        url: '*',
        title: 'Page not found. 404',
        jsx: <NotFoundPage/>,
    },

    'home': {
        url: '/',
        title: 'Dashboard',
        jsx: <AuthRequired><Dashboard/></AuthRequired>,
    },

    'employees': {
        url: '/employees-list',
        title: 'Employees',
        jsx: <AuthRequired><EmployeesManagementEmails/></AuthRequired>,
    },

    'myInformation': {
        url: '/my-information',
        title: 'Information',
        jsx: <AuthRequired><MyInformation/></AuthRequired>,
    },


    'newOrder': {
        url: '/new-order',
        title: 'New Order',
        jsx: <AuthRequired><NewOrder/></AuthRequired>,
    },


    'myResults': {
        url: '/my-results',
        title: 'My Results',
        jsx: <AuthRequired><MyResults/></AuthRequired>,
    },


    'activateKit': {
        url: '/activate-kit',
        title: 'Activate Kit',
        jsx: <AuthRequired><ActivateKit/></AuthRequired>,
    },

};

export {notAuthPages, authPages} ;