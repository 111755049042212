import React from "react";
import {Button, Result} from 'antd';
import {NavLink} from "react-router-dom";


const PaymentCancel = () =>
    <Result
        style={{paddingTop: 160}}
        status="error"
        title="Payment has been cancelled!"
        subTitle="Try making payment again."
        extra={<NavLink to={'/'}>
            <Button type="primary">
                Go to main page
            </Button>
        </NavLink>}

    />

export default PaymentCancel;