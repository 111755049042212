import {Table} from "antd";
import styled from "styled-components";


const FinalizedKitsTable = styled(Table)`

  & .ant-pagination-item-active {
    border-color: #01a9ac;

    & a {
      color: #01a9ac;
    }
  }

`

export {FinalizedKitsTable}