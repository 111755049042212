import React, {useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "../../../features/hooks/useLocalStorage";
import {AuthContext} from '../AuthContext';


const getAuthContext = () => {
    /* This is a function what getting a current localstorage object by key - authContext.
    Result this function is an init state for AuthContex */

    const authContextString = localStorage.getItem('authWebProviderContext');
    return JSON.parse(authContextString) || null;
}

const AuthRequired = ({children}) => {
    /* Custom Auth controller component  */

    const navigate = useNavigate();
    const {authContext} = useContext(AuthContext);

    useEffect(() => {
        if (authContext === null) {
            navigate('/login');
        }
    }, [authContext]);

    return children;
}

const AuthProvider = ({children}) => {
    const [authContext, setAuthContext] = useLocalStorage('authWebProviderContext', getAuthContext());

    const login = async (tokens, webProviderData) => {
        /*
        This function waiting from Login component two params:
        @tokens object
        @patientData object   */
        setAuthContext({...tokens, webProviderData}); // save tokens and parse user_id
    }

    const authConfig = {
        /* headers object for all secure requests. `ECL` it's not standard, but it's custom token for security. */

        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const updateWebProviderData = newStateData => {

        setAuthContext(prevState => {
            const {webProviderData} = prevState;
            return {...prevState, webProviderData: {...webProviderData, ...newStateData}};
        });

    }

    const logout = () => {
        /* Logout function. Clear all localstorage keys and turn off AuthContex */
        setAuthContext(null); // change react context
    }

    return <AuthContext.Provider
        value={
            {
                authContext, authConfig,
                login, logout,
                updateWebProviderData
            }
        }>
        {children}
    </AuthContext.Provider>
}


export {AuthProvider, AuthRequired}