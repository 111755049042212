import React, {useState, useEffect} from "react";
import restAPI from "../../../features/restAPI";

import {CloseOutlined} from "@ant-design/icons";
import {Col, Drawer, Row} from "antd";
import KitsTable from "./KitsTable";

const PatientDetails = ({onClose, open, selectedPatient, authConfig, authContext}) => {
    const [kitsList, setKitsList] = useState(null);
    const [isLoading, setIsLoading] = useState(null);


    const loadKitsForCurrentPatient = async () => {

        try {
            setIsLoading(true);
            const response = await restAPI.get(`/kits/all/?patient=${selectedPatient?.id}&ordering=-created`, authConfig);

            setKitsList(response?.data?.results);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };

    useEffect(() => {

        if (selectedPatient?.id && kitsList === null) {
            (async () => await loadKitsForCurrentPatient())();
        }

    }, [selectedPatient]);  // listen selectedPatient for updating data


    const onCloseHandler = () => {
        setKitsList(null);
        onClose();
    }


    return <Drawer
        closeIcon={<CloseOutlined/>}
        title={`${selectedPatient?.last_name} ${selectedPatient?.first_name}`}
        width={window.innerWidth > 1200 ? '80%' : '92%'}
        closable={window.innerWidth <= 1200}
        onClose={onCloseHandler}
        open={open}
    >

        {/*<Spin spinning={isLoading}>*/}
        <Row gutter={[24, 24]}>

            <Col xl={24}>
                <KitsTable
                    authConfig={authConfig}
                    kitsList={kitsList}
                    isLoading={isLoading}
                    authContext={authContext}
                />
            </Col>

        </Row>
        {/*</Spin>*/}
    </Drawer>;

}


export default PatientDetails;