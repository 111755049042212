import dayjs from "dayjs";
import {loadStripe} from '@stripe/stripe-js';
import restAPI from "../../../features/restAPI";

import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../auth/AuthContext";
import {authPages} from "../../../routing/config";

import {StatesAutoCompleteUseForm} from "../../../components/StatesAutoComplete";
import MainPageTitle from "../../../components/MainPageTitle";


import {
    LoadingOutlined,
    ShoppingCartOutlined,
    UserAddOutlined,
    DollarOutlined,
    CheckOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    ManOutlined,
    WomanOutlined,
} from "@ant-design/icons";
import {
    Button, Col, Divider, message, Row, Steps, DatePicker, Spin, Tag, Input, Typography, Select, Form, Space
} from "antd";


const {Option} = Select;


// TODO - re-build this better way
// DEV key
// const stripePromise = loadStripe('pk_test_51NQX1MKbmzi38XjIgKLJeHOgtR8Sg6LKQaJxYTDmvC20zoUuRdBhDueZhdrJneVElw8qlGP2jKfCWqj8ioOoAWFY00fbuczKB2');

// LIVE key
const stripePromise = loadStripe('pk_live_51NQX1MKbmzi38XjIxakuqR9kjehxvOlJOnOsPNPqd9fvgkIbtqexLU11mjhUUkVP4LExn0FLZFTffkLKHEnUX0Iy00q7jY3QRr');
// ---------------- re-build this better way Stripe END ---------------- //

const STEPS = {
    CHOOSE_PRODUCT: 0,
    ASSIGN_TO_PATIENT: 1,
    CHECKOUT: 2,
};

const phoneNumberMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (value = !x[2]
        ? x[1]
        : `(${x[1]}) ${x[2]}${x[3] ? "-" + x[3] : ""}`);
}

const ChooseProductComponent = ({productList, setSelectedProduct, selectedProduct, moveToPatientCreatingStep}) => {
    /*  Step 1  */

    //  Means this product list will not be shown in the list, but displayed on BO.
    const excluded = ["AP", "CE", "RA", "FA", "SK",];

    if (!productList) return null;

    // sort productList by product.price z-a
    productList.sort((a, b) => b.price - a.price);

    /* Prepare options for <Select/> component */
    const options = productList &&
        productList
            .filter(product => !excluded.includes(product.code))
            .map(product => ({
                value: product.id,
                label: <Typography.Text>
                    {product.name} <Divider type={'vertical'}/> <Tag color={"#01a9ac"}>${product.price}</Tag>
                </Typography.Text>
            }));

    /* Push product data to main component state */
    const selectOnChangeHandler = value => setSelectedProduct(productList.find(p => p.id === value))

    return <>
        <Typography.Title level={4} style={{fontWeight: 400, marginTop: 30}}>Choose the product</Typography.Title>

        <Form>
            <Form.Item>
                <Select
                    size={'medium'}
                    placeholder={'Select product'}
                    defaultValue={selectedProduct?.id || null}
                    onChange={selectOnChangeHandler}
                    menuItemSelectedIcon={<CheckOutlined/>}
                >
                    {options.map(({value, label}) =>
                        <Option key={value} value={value}>{label}</Option>)
                    }
                </Select>
            </Form.Item>
        </Form>

        <Button
            disabled={selectedProduct === null}
            type={'primary'}
            icon={<DoubleRightOutlined/>}
            onClick={moveToPatientCreatingStep}
        >
            Create the patient
        </Button>
    </>
};

const AssignToPatientComponent = ({
                                      returnToChooseTheProduct,
                                      moveToCheckoutStep,
                                      setNewPatientData,
                                      newPatientData
                                  }) => {
    /*  Step 2  */
    const [form] = Form.useForm();

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await setNewPatientData(values);
            await moveToCheckoutStep();
        } catch (error) {
            console.error('Form validation failed:', error);
        }
    };

    useEffect(() => {
        console.log(newPatientData);
        if (newPatientData) {
            form.setFieldsValue(newPatientData);
        }
    }, [newPatientData, form]);

    return <>
        <Typography.Title level={4} style={{fontWeight: 400, marginTop: 30}}>Assign to patient</Typography.Title>

        <Form
            name={'assign-to-patient'}
            form={form}
            style={{marginTop: 30}}
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
            initialValues={newPatientData || {}} // Setting default values
        >
            <Row gutter={16}>
                <Col lg={8} xs={24}>
                    <Form.Item
                        name="first_name"
                        label="First name"
                        rules={[{
                            required: true, message: 'Please input first name',
                        },]}
                    >
                        <Input placeholder={'First name'}/>
                    </Form.Item>
                </Col>

                <Col lg={8} xs={24}>
                    <Form.Item
                        name="last_name"
                        label="Last name"
                        rules={[{required: true, message: ''}]}
                    >
                        <Input placeholder={'Last name'}/>
                    </Form.Item>
                </Col>

                <Col lg={8} xs={24}>
                    <Form.Item
                        name="gender"
                        label="Sex"
                        rules={[{required: true, message: "Can't be empty"}]}
                    >
                        <Select
                            placeholder="Please choose a sex"
                            defaultOpen={0}
                            style={{width: '100%'}}
                            options={[{label: "Male", value: "M"}, {label: "Female", value: "F"},]}
                        />
                    </Form.Item>
                </Col>

                <Col lg={8} xs={24}>
                    <Form.Item
                        name="date_of_birth"
                        label="Date of birth"
                        rules={[{required: true, message: ''}]}
                    >
                        <DatePicker style={{width: '100%'}} format={'MM-DD-YYYY'}/>
                    </Form.Item>
                </Col>

                <Col lg={8} xs={24}>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter email',

                            },
                            {
                                // eslint-disable-next-line
                                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                message: 'Please enter valid email',
                            }
                        ]}
                    >
                        <Input placeholder="Please enter email"/>
                    </Form.Item>
                </Col>

                <Col lg={8} xs={24}>
                    <Form.Item
                        name="phone"
                        label="Phone"
                        rules={[
                            {required: true, message: 'Please enter phone',},
                            {pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/, message: 'Please enter valid phone number',}
                        ]}
                    >
                        <Input
                            onChange={({target: {value}}) => {
                                const prettyPhoneNumber = phoneNumberMask(value);

                                form.setFieldValue('phone', prettyPhoneNumber);
                            }}
                            placeholder="Please enter phone"
                        />
                    </Form.Item>
                </Col>

                <Col lg={10} xs={24}>
                    <Form.Item
                        name="address"
                        label="Address"
                        rules={[{
                            required: true, message: 'Please input an address',
                        },]}
                    >
                        <Input placeholder={'Address'}/>
                    </Form.Item>
                </Col>

                <Col lg={6} xs={24}>
                    <Form.Item
                        name="city"
                        label="City"
                        rules={[{required: true, message: 'Please input a city'}]}
                    >
                        <Input placeholder={'City'}/>
                    </Form.Item>
                </Col>

                <Col lg={4} xs={24}>
                    <StatesAutoCompleteUseForm form={form}/>
                </Col>


                <Col lg={4} xs={24}>
                    <Form.Item
                        name="postcode"
                        label="Zip code"
                        rules={[{required: true, message: 'Please input a zip'}]}
                    >
                        <Input placeholder={'Zip code'}/>
                    </Form.Item>
                </Col>

                <Col lg={24} xs={24}>
                    <Form.Item>
                        <Space>
                            <Button
                                style={{marginTop: 30}}
                                onClick={returnToChooseTheProduct}
                                type={'dashed'}
                                icon={<DoubleLeftOutlined/>}
                            >
                                Return to products
                            </Button>

                            <Button
                                style={{marginTop: 30}}
                                onClick={handleSubmit}
                                type={'primary'}
                                icon={<DoubleRightOutlined/>}
                            >
                                Go to checkout
                            </Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </>;
};

const CheckoutComponent = ({newPatientData, moveToPatientCreatingStep, payAndSaveOrderHandler}) => {
    /*  Step 3  */

    return <Row>

        <Col xxl={24}>
            <Typography.Title level={4} style={{fontWeight: 400, marginTop: 30}}>
                Order confirmation and payment
            </Typography.Title>
        </Col>

        <Col xxl={24}>
            <Row>
                {newPatientData && <>
                    <Col xxl={24} style={{marginBottom: 30}}>
                        <Typography.Title level={5}>
                            Patient information:
                        </Typography.Title>

                        Full name:
                        <Divider type={'vertical'}/>
                        {newPatientData.first_name} {newPatientData.last_name} {newPatientData.gender === 'M' ?
                        <ManOutlined/> : <WomanOutlined/>}
                        <br/>

                        Date of birth:
                        <Divider type={'vertical'}/>
                        {dayjs(newPatientData.date_of_birth).format('MM-DD-YYYY')}
                        <br/>


                        Email:
                        <Divider type={'vertical'}/>
                        {newPatientData.email}
                        <br/>

                        Phone:
                        <Divider type={'vertical'}/>
                        {newPatientData.phone}
                        <br/>


                        Address:
                        <Divider type={'vertical'}/>
                        {newPatientData.address}, {newPatientData.city}, {newPatientData.state}, {newPatientData.postcode}
                    </Col>


                    <Col xxl={24}>
                        <Typography.Title level={5}>Shipping information:</Typography.Title>

                        <Typography.Text>
                            Order receiver:
                            <Divider type={'vertical'}/>
                            {newPatientData.first_name} {newPatientData.last_name}
                        </Typography.Text>

                        <br/>

                        <Typography.Text>
                            Delivery address:
                            <Divider type={'vertical'}/>
                            {newPatientData.address}, {newPatientData.city}, {newPatientData.state}, {newPatientData.postcode}
                        </Typography.Text>

                    </Col>
                </>}

            </Row>
        </Col>

        <Col lg={24} xs={24}>
            <Form.Item>
                <Space>
                    <Button
                        style={{marginTop: 30}}
                        onClick={moveToPatientCreatingStep}
                        type={'dashed'}
                        icon={<DoubleLeftOutlined/>}
                    >
                        Return to patient information
                    </Button>

                    <Button
                        style={{marginTop: 30}}
                        onClick={payAndSaveOrderHandler}
                        type={'primary'}
                        icon={<DoubleRightOutlined/>}
                    >
                        Pay now
                    </Button>
                </Space>
            </Form.Item>
        </Col>
    </Row>
};

const NewOrder = () => {
    /* New product form for WebProvider */

    const [messageApi, contextHolder] = message.useMessage();
    const {authConfig, authContext} = useContext(AuthContext); // auth data from current session
    const {newOrder} = authPages;
    const [isLoading, setIsLoading] = useState(true);

    const [productList, setProductList] = useState(null);


    /* Stepper brains */
    const [currentStepperStep, setCurrentStepperStep] = useState(STEPS.CHOOSE_PRODUCT);
    const [selectedProduct, setSelectedProduct] = useState(null);  // selected product for current order
    const [newPatientData, setNewPatientData] = useState(null);

    const moveToPatientCreatingStep = () => {
        setCurrentStepperStep(STEPS.ASSIGN_TO_PATIENT);
    }

    const returnToChooseTheProduct = () => {
        setCurrentStepperStep(STEPS.CHOOSE_PRODUCT);
    }

    const moveToCheckoutStep = () => {
        setCurrentStepperStep(STEPS.CHECKOUT);
    }
    /* --- */

    const success = message => {
        messageApi.open({
            type: 'success', content: `${message}`, duration: 3
        });
    };

    const payAndSaveOrderHandler = async () => {
        /* Current request sends data to the Stripe  */
        try {
            // Prepare patient data for the request
            const patientData = {
                ...newPatientData,
                date_of_birth: dayjs(newPatientData.date_of_birth).format('YYYY-MM-DD')
            }

            // Prepare product id and price for the Stripe request
            const {id: productId, name} = selectedProduct;
            const price = selectedProduct.price * 100;
            const quantity = 1;

            // Check if webProvider ID is defined
            if (!authContext?.webProviderData?.id) throw new Error('WebProvider ID is not defined');

            // Prepare webProvider ID for the request
            const {id: webProviderId} = authContext?.webProviderData;


            // Prepare data for the request to the Stripe
            const orderData = {
                name,
                price,
                quantity,
                productId,
                patientData,
                webProviderId
            }

            // // Debug data before sending to the Stripe
            // console.log("orderData", orderData)

            // const response = await fetch('http://0.0.0.0:8080/api/v0/stripe/checkout-web-provider-order/', {
            const response = await fetch('https://django-api.efunctional.com/api/v0/stripe/checkout-web-provider-order/', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer b4950c7d61f0976220c3754b81a78068690a9856'
                }, body: JSON.stringify(orderData),
            });

            const data = await response.json();
            if (data.error) {
                console.error(data.error);
                return;
            }

            const stripe = await stripePromise;
            const result = await stripe.redirectToCheckout({sessionId: data.id});

            if (result.error) {
                console.error(result.error.message);
            }


        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        const loadProducts = async () => {
            try {
                setIsLoading(true);
                const getConfig = {
                    headers: authConfig.headers,
                }

                const response = await restAPI.get(`/products/`, getConfig);
                setProductList([...response?.data?.results]);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }

        (async () => {
            try {
                setIsLoading(true);

                await Promise.all([
                    loadProducts()
                ]);
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, []);


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        {contextHolder}

        <Row gutter={[24, 0]}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{newOrder.title}</MainPageTitle>
            </Col>


            <Col xs={24} lg={24} xl={12} xxl={22}>

                <Steps
                    status={'process'}
                    type={'default'}
                    responsive={true}
                    current={currentStepperStep}
                    items={[
                        {
                            title: <Typography.Text type={'secondary'}>Choose the product</Typography.Text>,
                            icon: <ShoppingCartOutlined/>,
                        },
                        {
                            title: <Typography.Text type={'secondary'}>Assign to patient</Typography.Text>,
                            icon: <UserAddOutlined/>,
                        },
                        {
                            title: <Typography.Text type={'secondary'}>Checkout</Typography.Text>,
                            icon: <DollarOutlined/>,
                        },
                    ]}
                />

                {currentStepperStep === STEPS.CHOOSE_PRODUCT && <ChooseProductComponent
                    productList={productList}
                    setSelectedProduct={setSelectedProduct}
                    selectedProduct={selectedProduct}
                    moveToPatientCreatingStep={moveToPatientCreatingStep}
                />}

                {currentStepperStep === STEPS.ASSIGN_TO_PATIENT && <AssignToPatientComponent
                    returnToChooseTheProduct={returnToChooseTheProduct}
                    moveToCheckoutStep={moveToCheckoutStep}
                    newPatientData={newPatientData}
                    setNewPatientData={setNewPatientData}
                />}

                {currentStepperStep === STEPS.CHECKOUT && <CheckoutComponent
                    moveToPatientCreatingStep={moveToPatientCreatingStep}
                    moveToCheckoutStep={moveToCheckoutStep}
                    newPatientData={newPatientData}
                    payAndSaveOrderHandler={payAndSaveOrderHandler}
                />}

            </Col>
        </Row>
    </Spin>
}

export default NewOrder;