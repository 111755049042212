import React, {useContext, useState, useEffect} from "react";
import MainPageTitle from "../../../components/MainPageTitle";


import {AuthContext} from "../../auth/AuthContext";
import restAPI from "../../../features/restAPI";
import {authPages} from "../../../routing/config";
import PatientDetails from "./PatientDetails";

import {Col, Row, Spin, message} from "antd";
import {LoadingOutlined} from "@ant-design/icons";


const MyResults = () => {
    const {myResults} = authPages;

    const [isLoading, setIsLoading] = useState(true);
    const {authContext, authConfig} = useContext(AuthContext); // auth data from current session

    const [open, setOpen] = useState(false);
    const [childrenDrawer, setChildrenDrawer] = useState(false);

    const [productsList, setProductsList] = useState(null);
    const [patientData, setPatientData] = useState(null);
     

    const getPatientData = async () => {
        try {
            const response = await restAPI.get(`/patients/all/?user_id=${authContext.employeePatientData?.auth_user}`, authConfig);

            if (response?.data?.count === 0) {
                throw "We can't find your account. Sorry 🙏";
            }

            setPatientData(response.data.results[0]);

        } catch (error) {
            if (error.hasOwnProperty('response')) {
            } else {
                console.error(error);
            }
        }
    }


    const loadProductList = async () => {
        setIsLoading(true);

        await restAPI.get('/products/', authConfig)
            .then(response => {

                const customProductsForFilteringComponent = response?.data?.results.map(
                    product => ({
                        ...product,
                        label: product?.name,
                        value: product?.name,
                        key: product?.id,
                    }));

                const addShowAll = [{
                    key: 1000,
                    label: 'Show all',
                    value: 'all',
                    code: 'all'
                }, ...customProductsForFilteringComponent];

                setProductsList(addShowAll);

            }).catch(err => {
                console.log(err);
                setProductsList([]);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                await Promise.all([
                    getPatientData(), loadProductList()
                ]);
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };
    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };

    const onClick = ({key}) => {
        message.info(`Click on item ${key}`);
    };

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{myResults.title}</MainPageTitle>
            </Col>


            <Col xs={24} lg={24}>

                <PatientDetails
                    authConfig={authConfig}
                    patient={patientData !== null ? patientData : null}
                    productsList={productsList !== null ? productsList : []}
                    open={open}
                    childrenDrawer={childrenDrawer}
                    onClose={onClose}
                    showDrawer={showDrawer}
                    showChildrenDrawer={showChildrenDrawer}
                    onChildrenDrawerClose={onChildrenDrawerClose}
                    onClick={onClick}
                    setIsLoading={setIsLoading}
                />

            </Col>
        </Row>
    </Spin>
}

export default MyResults;