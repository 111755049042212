import React, {useState, useEffect, useContext} from "react";

import dayjs from "dayjs";
import styled from 'styled-components';
import restAPI from "../../../features/restAPI";
import {authPages} from "../../../routing/config";
import {AuthContext} from "../../auth/AuthContext";


import {LoadingOutlined, ProfileOutlined, CalendarOutlined} from "@ant-design/icons";
import {Col, Row, Spin, List, Skeleton, Space, DatePicker, Divider} from "antd";

import MainPageTitle from "../../../components/MainPageTitle";

const StyledTrackingNumber = styled.div`
  display: none; // Hide by default

  @media only screen and (max-width: 768px) {
    display: block; // Show only on mobile devices
  }
`;

const StyledOtherInfo = styled.div`
  @media only screen and (max-width: 768px) {
    display: none; // Hide on mobile devices
  }
`;


const History = () => {
    const {ordersHistory} = authPages;

    const {authContext} = useContext(AuthContext); // auth data from current session

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const [isLoading, setIsLoading] = useState(true);
    const [ordersList, setOrdersList] = useState([]);

    // eslint-disable-next-line
    const [month, setMonth] = useState(null);


    const loadAllProviderOrders = async () => {

        try {
            const response = await restAPI.get(`/orders/web-provider/?web_provider=${authContext?.webProviderData?.id}`, authConfig)

            setOrdersList(response?.data?.results);


        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };

    useEffect(() => {
        (async () => {
            await loadAllProviderOrders();
        })();
        // eslint-disable-next-line
    }, []);

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{ordersHistory.title}</MainPageTitle>
            </Col>


            <Col xs={24} lg={24} xl={24}>
                <Space style={{margin: '0 0 20px 0'}}>
                    {/*<span>Filter orders by month:</span>*/}

                    {/*<DatePicker*/}
                    {/*    defaultValue={dayjs()}*/}
                    {/*    onChange={value => setMonth(dayjs(value).format('YYYY-MM'))}*/}
                    {/*    format={'YYYY-MM'}*/}
                    {/*    picker="month"*/}
                    {/*    inputReadOnly={true}*/}
                    {/*    size={'small'}*/}
                    {/*/>*/}
                </Space>

            </Col>

            <Col xs={24} lg={24}>

                <List
                    className="demo-loadmore-list"
                    loading={isLoading}
                    itemLayout="horizontal"
                    dataSource={ordersList}
                    renderItem={(item) => (
                        <List.Item
                            key={item.id}
                            actions={[
                                <p><CalendarOutlined/> {dayjs(item?.created).format('YYYY-MM-DD')}</p>,
                            ]}
                        >
                            <Skeleton avatar title={false} loading={isLoading} active>
                                <List.Item.Meta
                                    avatar={<ProfileOutlined style={{fontSize: 40}}/>}
                                    title={<span>Order ID: {item?.id}</span>}
                                    description={<Space>

                                        <StyledOtherInfo>
                                            <>Status: {item?.status}</>
                                            <Divider type={'vertical'}/>
                                            <>Tracking # {item?.tracking_notes ? item?.tracking_notes : "TBD"}</>
                                            <Divider type={'vertical'}/>
                                            <>Kits: {item?.order_kits.map(k => <>{k.barcode}&nbsp;</>)}</>
                                            <Divider type={'vertical'}/>
                                        </StyledOtherInfo>


                                        <StyledTrackingNumber>
                                            <>Tracking number: {item?.tracking_notes ? item?.tracking_notes : "TBD"}</>
                                            <Divider type={'vertical'}/>
                                        </StyledTrackingNumber>
                                    </Space>}
                                />
                                <div>

                                </div>
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    </Spin>
}

export default History;