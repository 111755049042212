import styled from "styled-components";
import dayjs from 'dayjs';
import React, {useContext, useState, useEffect} from "react";
import MainPageTitle from "../../../components/MainPageTitle";

import {AuthContext} from "../../auth/AuthContext";
import restAPI from "../../../features/restAPI";
import {authPages} from "../../../routing/config";
import {NavLink} from "react-router-dom";

import {LoadingOutlined} from "@ant-design/icons";
import {Spin, Col, Row, Typography, Card, Statistic, Divider} from "antd";


const CardTitle = styled.div`
  width: 100%; /* Adjust this value based on your need */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  text-align: center;
`;

const CustomCard = styled(Card)`
  user-select: none;
  border-width: 2px;
  border-color: #01a9ac;
  background: linear-gradient(to top, #01a9ac, #01dbdf);

  & .ant-statistic-content {
    color: #fff;
  }

  & .ant-statistic-title {
    color: #e8e8e8;
  }

  &:hover {
    background: linear-gradient(to left, #01a9ac, #01dbdf);
  }
`


const Dashboard = () => {
    const {
        home
    } = authPages;

    const {
        authContext, // data about current user
        authConfig, // header with token for Axios requests
    } = useContext(AuthContext); // auth data from current session

    const {
        webProviderData // Patient account data
    } = authContext;

    const [isLoading, setIsLoading] = useState(true);
    const [employeeData, setEmployeeData] = useState(null);
    const [contractData, setContractData] = useState(null);
    const [productList, setProductList] = useState(null);
    const [remainingDays, setRemainingDays] = useState(null);


    const loadProductsByEmployerContract = async () => {
        try {
            const contractResponse = await restAPI.get(`/contracts/?client_id=${authContext?.employeePatientData?.client}`, authConfig);

            // ------------------------------------------------------------------------------- Count Remaining Days //
            const currentDate = dayjs();
            const diffInDays = dayjs(contractResponse.data.results[0]?.finish).diff(currentDate, 'day');
            setRemainingDays(diffInDays);
            // ------------------------------------------------------------------------------- Count Remaining Days //

            setContractData(contractResponse.data.results[0]); // set contract data to state

            const products = contractResponse.data.results[0].products;
            const productIds = products.join(',');
            const getConfig = {
                headers: authConfig.headers,
                params: {
                    ids: productIds
                }
            }
            const response = await restAPI.get(`/products/`, getConfig);
            setProductList([...response?.data?.results]); // set the Contract Products list to state

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                await Promise.all([
                    loadProductsByEmployerContract()
                ]);
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, []);


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}} gutter={[16, 16]}>

            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{home.title}</MainPageTitle>
            </Col>

            <Col xs={24} md={24} lg={24} xl={10}>
                <Typography.Paragraph>
                    Dear {webProviderData?.name}&nbsp;👋,
                </Typography.Paragraph>

                {/*<Typography.Paragraph>*/}
                {/*    We understand the importance of your health and well-being.*/}
                {/*    Act now to take advantage of the exceptional healthcare services we provide.*/}
                {/*    With only <strong>{remainingDays} days</strong> remaining, make the most of your employer*/}
                {/*    company's funds, ensuring you receive the best possible tests in the US.. <br/>*/}
                {/*</Typography.Paragraph>*/}

                {/*<Typography.Paragraph>*/}
                {/*    Don't let this opportunity slip away! Place your order <NavLink to={'new-order'}>here</NavLink> to*/}
                {/*    access our wide range of high-quality*/}
                {/*    tests. Additionally, you can conveniently check your results by clicking&nbsp;*/}
                {/*    <NavLink to={'my-results'}>here</NavLink>.*/}
                {/*</Typography.Paragraph>*/}

                <Typography.Paragraph>
                    Here we need to add some text about the services we provide.
                </Typography.Paragraph>
                <br/>

                <Divider/>

                <Typography.Paragraph>
                    Best regards, <br/>
                    Efunctional Team
                </Typography.Paragraph>
            </Col>

            {/*offset*/}
            <Col xs={0} md={0} lg={4}></Col>
            {/*offset*/}

            <Col xs={24} md={24} lg={24} xl={10}>

                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <CustomCard bordered={true} hoverable={true}>
                            <Statistic
                                title="Your profit"
                                value={0}
                                precision={2}
                                prefix={"$"}
                            />
                        </CustomCard>
                    </Col>

                    <Col span={12}>
                        <CustomCard bordered={true} hoverable={true}>
                            <Statistic
                                title="Kits selling"
                                value={0}
                            />
                        </CustomCard>
                    </Col>

                </Row>

                <Divider>
                    Available kits
                </Divider>

                <Col span={24} style={{marginBottom: 30}}>
                    <Row gutter={[12, 12]}>
                        {productList !== null && productList.map(product => {
                            return <Col sm={24} lg={8} key={product.id}>
                                <Card bordered={true} hoverable={true}>
                                    <CardTitle title={product.name}>{product.name}</CardTitle>
                                </Card>
                            </Col>
                        })}
                    </Row>
                </Col>

            </Col>

        </Row>
    </Spin>
}

export default Dashboard;