import states from './states.json'
import statesNyIncluding from './states_ny_included.json'
import React from 'react';
import {Form, Select} from 'antd';

const {Option} = Select;

const StatesAutoCompleteUseForm = ({form, isNyStateIncluded = false}) => {

    let statesJSON;

    if (!isNyStateIncluded) {
        statesJSON = states
    } else {
        statesJSON = statesNyIncluding
    }

    const statesArray = JSON.parse(statesJSON);


    return (
        <Form.Item
            name="state"
            label="State"
            rules={[{required: true, message: 'Please choose state'}]}
        >
            <Select
                showSearch
                placeholder="Please choose state"
                optionFilterProp="children"
                style={{width: "100%"}}
                onChange={(value) => form.setFieldsValue({state: value})}
            >
                {statesArray.map(state => (
                    <Option key={state.postal} value={state.postal}>
                        {state.name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};


export {StatesAutoCompleteUseForm};
